<template>
  <div class="directory">
    <v-row class="ma-0 pa-0">
      <v-col
        class="ma-0 pa-0 leftContent"
        tabindex="0"
        cols="12"
        md="6"
        sm="12"
        xs="12"
      >
        <v-row
          style="
            margin: 0 12px;
            min-height: calc(100vh - 76px);
            display: inherit;
            background: #ffffff;
            margin-right: 6px;
          "
        >
          <v-tabs
            v-model="tab"
            centered
            class="tabRemovePadding"
            active-class="globalDirectoryTab"
            silder-color="#000000"
            grow
            style="padding: 0"
            @change="tabChange"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab>
              <span>All</span>
            </v-tab>

            <v-tab v-if="showFavoriteFunction == true">
              <!-- <img
                src="@/assets/images/map/star_grey.png"
                style="width: 15px; margin-right: 10px"
              /> -->
              <i class="iconfont icon-wujiaoxing"></i>
              <span>Favorites</span>
            </v-tab>
          </v-tabs>
          <v-col cols="12" md="12" sm="12" xs="12">
            <div class="directorySearch" v-if="directorySearch">
              <p
                style="
                  margin-bottom: 0;
                  margin-left: 15px;
                  font-size: 13px;
                  color: #6c6c6c;
                "
              >
                Search by:
              </p>
              <v-btn
                class="ma-2 dir-btn "
                rounded
                small
                outlined
                v-for="(i, k) in searchBtn"
                :key="k"
                :class="{
                  'x-global-btn-active': i.active === true,
                  'x-btn-border-active': i.active === true,
                }"
                @click="searchBtnClick(k)"
              >
                {{ i.name }}
              </v-btn>
            </div>
            <v-text-field
              :label="searchText"
              v-model="keyword"
              v-if="directorySearch"
            >
              <template #prepend-inner>
                <div style="width: 22px">
                  <img
                    style="margin: auto 0; width: 100%"
                    src="@/assets/images/map/search.png"
                  />
                </div>
              </template>
            </v-text-field>
            <v-row class="ma-0">
              <v-col cols="12" md="6" sm="6" xs="6">
                <v-checkbox
                  v-model="checkIn"
                  @change="checkInChange"
                  v-if="showCheckIn"
                  hide-details
                  class="gobalCheckBox"
                >
                  <template v-slot:label>
                    <span style="color: #000;margin-right: 10px;">Show only Checked-in</span>
                    <!-- <i class="iconfont icon-dibiao"></i> -->
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="6">
                <!-- <v-select
                  :items="campusData"
                  item-text="name"
                  item-value="id"
                  v-model="campusSelect"
                  persistent-hint
                  return-object
                  label="Campus"
                  @change="campusChange"
                  v-if="showCampusSelect && campusData.length > 1"
                  class="gobalSelect"
                ></v-select> -->
                <div class="selectDir" v-if="showCampusSelect && campusData.length > 1">
                  <div tabindex="1" placeholder="Campus">{{campusSelect.name}}</div>
                  <ul>
                    <li v-for="(item,key) in campusData" :key="key" @mousedown="campusChange(item)" >
                      <i class="iconfont icon-ictick" style="margin-right:10px" v-if="item.id == campusSelect.id"></i>
                      <span style="font-size:13px" :class="{'x-global-li-color':item.id == campusSelect.id}">{{item.name}}</span>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <div class="content-container" style="border: 0; width: 100%">
            <div v-show="!showStaffList">
              <!--<i class="iconfont icon-ren" style="font-size: 3rem;color:#2D7D74;text-align: center"></i>-->
              <div
                tabindex="0"
                style="width: 100%; text-align: center"
                v-show="allNotData"
              >
                <!-- <p style="height: 0;opacity: 0;">Directory image</p> -->
                <img
                  v-show="!showNotData"
                  src="@/assets/images/map/directory@2x.png"
                  style="width: 15%; margin: 40px 12px"
                  alt=""
                />
                <p v-show="showNotData" style="font-weight: bolder">
                  No matching results
                </p>
              </div>
              <div
                tabindex="0"
                style="width: 100%; text-align: center"
                v-show="favoritesNotData"
              >
                <!-- <p style="height: 0;opacity: 0;">Directory image</p> -->
                <img
                  v-show="favoritesNotFiterNotData"
                  src="@/assets/images/map/fav_icon@2x.png"
                  style="width: 15%; margin: 40px 12px"
                  alt=""
                />
                <p
                  v-show="favoritesNotFiterNotData"
                  style="font-weight: bolder"
                >
                  No Favorites
                </p>
                <p v-show="favoritesFiterNotData" style="font-weight: bolder">
                  No matching results
                </p>
                <p v-show="favoritesNotFiterNotData" style="padding: 0 10%">
                  To add people to Favorites, click on the star icon next to
                  their name in their profile screen
                </p>
              </div>
              <!-- <p tabindex="0" style="font-size: 2rem;font-weight: bold;margin-bottom: 30px">Directory</p> -->
              <v-row v-show="allNotData">
                <v-col cols="1" md="3" sm="3"></v-col>
                <v-col cols="11" md="9" sm="9" class="pa-0">
                  <div
                    class="notes"
                    style="min-height: 200px"
                    v-show="!showNotData"
                  >
                    <p class="dot-p">
                      <span class="dot x-background"></span>
                      <span class="note" tabindex="0"
                        >Look up a colleague’s contact information</span
                      >
                    </p>
                    <p class="dot-p">
                      <span class="dot x-background"></span>
                      <span class="note" tabindex="0"
                        >View employee photos</span
                      >
                    </p>
                    <p class="dot-p" v-if="showDepartmentFunction == true">
                      <span class="dot x-background"></span>
                      <span class="note" tabindex="0"
                        >Search / view employees by department</span
                      >
                    </p>
                    <p class="dot-p" v-if="showAttributesFunction == true">
                      <span class="dot x-background"></span>
                      <span class="note" tabindex="0">Search / view specialties</span>
                    </p>
                  </div>
                </v-col>

                <!-- <v-col cols="12" md="3" sm="4"></v-col> -->
              </v-row>
            </div>
            <div v-show="showStaffList" class="staffList">
              <v-col cols="12" md="12" sm="12" xs="12">
                <!-- <p class="totalNumber" tabindex="0" :aria-label="'result'+totalNumber">Result: {{ totalNumber }}</p> -->
                <!--<p class="totalNumber" v-show="showTotal" tabindex="1">Result: {{ totalNumber }}</p>-->
                <staff
                  v-for="(item, index) in attendees"
                  :data="item"
                  :showAttributesFunction="showAttributesFunction"
                  :showFavoriteFunction="showFavoriteFunction"
                  @showStaffDetail="showStaffDetail(item)"
                  @attritutesSearch="attritutesSearch"
                  @keyup.enter.native="showStaffDetail(item)"
                  style="cursor: pointer"
                  tabindex="0"
                ></staff>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <v-pagination
                  v-model="currentPage"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  @input="pageChange"
                  @previous="pagePrevious"
                  @next="pageNext"
                  class="pageNum"
                ></v-pagination>
              </v-col>
            </div>
          </div>
        </v-row>
      </v-col>
      <v-col
        class="ma-0 pa-0 rightContent"
        tabindex="0"
        v-show="showRight"
        cols="12"
        md="6"
        sm="12"
        xs="12"
      >
        <v-row
          style="
            margin: 0 12px;
            min-height: calc(100vh - 76px);
            display: inherit;
            background: #ffffff;
            margin-left: 6px;
          "
        >
          <staffInfo
            ref="refStaffInfo"
            :data="staffDetail"
            :showAttributesFunction="showAttributesFunction"
            :showFavoriteFunction="showFavoriteFunction"
            @updateData="updateData"
            @changeShowList="changeShowList"
            @showMore="showMore"
            @attritutesSearch="attritutesSearch"
          ></staffInfo>
        </v-row>
      </v-col>
      <v-col
        class="ma-0 pa-0 rightContent"
        v-show="!showRight"
        cols="12"
        md="6"
        sm="12"
        xs="12"
      >
        <v-row
          style="
            margin: 0 12px;
            height: calc(100vh - 76px);
            display: inherit;
            background: #ffffff;
            display: flex;
            align-items: center;
            margin-left: 6px;
          "
        >
          <div style="width: 100%; text-align: center">
            <img
              src="@/assets/images/map/Profile@2x.png"
              style="width: 15%; margin: 20px 12px"
              alt=""
            />
            <p>Employee Profile</p>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import staff from "@/views/dashboard/componentsHa/globalStaff";
import staffInfo from "@/views/dashboard/componentsHa/globalStaffInfo";

export default {
  name: "globalDirectory",
  components: {
    staff,
    staffInfo,
  },
  watch: {
    attendees(newVal, oldVal) {
      console.log("newVal:", newVal);
      this.totalNumber = newVal.length;
      // if (newVal.length > 0) {
      //   this.showTotal = true;
      // }
    },
    keyword:{
      handler(val, oldVal){
          if(this.timer) clearTimeout(this.timer)
          this.timer = setTimeout(()=>{
              // console.log('输入停止后400毫秒，开始调接口', this.keyword)
              this.search();
          }, 800)
      },
      deep: true
    }
  },
  data() {
    return {
      // showTotal: false,
      timer: null,
      tab: 0,
      totalNumber: 0,
      cancelRequest: null,
      valid: false,
      name: "",
      nameRules: "",
      showStaffList: false, //还没进行搜索或搜索结果为空
      showEmpty: true,
      attendees: [], //人员名单
      staffTotal: 0, //查询出人员总数
      staffDetail: {}, //员工详细信息
      showRight: false, //右侧员工详细信息展示
      axiosNum: 0,
      cancel: {},
      showOrganazation: false, //显示员工组织架构
      timeOut: null,
      reportToIndex: 0,
      reportFromIndex: 0,
      orgStructureSize: 5,
      reportToTotal: 0,
      reportFromTotal: 0,
      searchBtn: [
        { name: "Name or Email", active: true },
        { name: "Department", active: false },
        // {"name":"Employee Attributes","active": false},
      ],
      checkIn: false,
      keyword: "",
      keywordType: "",
      checkedIn: "",
      pageSize: 20,
      page: 0,
      currentPage: 0,
      pageCount: 0,
      totalVisible: 10,
      campusData: [],
      campusSelect: {
        name: "",
        id: "",
      },
      showNotData: false,
      showAttributesFunction: false,
      showDepartmentFunction: true,
      // showFavoriteFunction: false,
      showFavoriteFunction: true,
      directorySearch: true,
      isFavorites: false,
      showCampusSelect: false,
      searchText: "Type name or email",
      showCheckIn: true,
      allNotData: true,
      favoritesNotData: false,
      favoritesNotFiterNotData: true,
      favoritesFirstNotData: false,
      favoritesFiterNotData: false,
    };
  },

  created() {
    
    if (localStorage.client && localStorage.client == "CX Campus") {
      // this.showFavoriteFunction = true;
      this.showAttributesFunction = true;
      this.showDepartmentFunction = true;
      this.searchBtn = [
        { name: "Name or Email", active: true },
        { name: "Department", active: false },
        {"name":"Specialties","active": false},
      ];
    }else if(localStorage.client && localStorage.client == "WarnerMedia"){
      this.showDepartmentFunction = false;
      this.searchBtn = [
        { name: "Name or Email", active: true },
        // { name: "Department", active: false },
        // {"name":"Specialties","active": false},
      ];
    }else if(localStorage.client && localStorage.client == "Aruba"){
      this.showDepartmentFunction = false;
      this.searchBtn = [
        { name: "Name or Email", active: true },
        // { name: "Department", active: false },
        // {"name":"Specialties","active": false},
      ];
    }else if(localStorage.client && localStorage.client == "HPE Campus"){
      this.showDepartmentFunction = false;
      this.searchBtn = [
        { name: "Name or Email", active: true },
        // { name: "Department", active: false },
        // {"name":"Specialties","active": false},
      ];
    }else if(localStorage.client && localStorage.client == "PinterestCampus"){
      this.showDepartmentFunction = false;
      this.searchBtn = [
        { name: "Name or Email", active: true },
        // { name: "Department", active: false },
        // {"name":"Specialties","active": false},
      ];
    }
    // console.log("oooooooo");
    // console.log(
    //   localStorage.enableCampusCheckIn,
    //   "p0p0p0p0",
    //   this.$store.state.globalShowCheckIn,
    //   localStorage.globalShowCheckIn
    // );
    if (localStorage.globalShowCheckIn != "true") {
      this.showCheckIn = false;
      this.showCampusSelect = false;
    }

    if (localStorage.globalShowCheckIn == "true") {
      this.showCheckIn = true;
    }

    this.getCampusData();
  },

  mounted() {
    if(localStorage.skill && localStorage.skill != '' && this.showAttributesFunction == true){
      this.searchBtn.map((i) => {
        if (i.name == "Specialties") {
          i.active = true;
        }else{
          i.active = false;
        }
      });
      this.keyword = localStorage.skill;
      this.page = 0;
      this.getData();
    }
  },

  methods: {
    // reportTo或reportFrom显示更多的人
    async showMore(type, id) {
      this.bus.$emit("loading", true);
      if (type == "to") {
        this.reportToIndex++;
        let orgStructureTo;
        await this.getOrgStructure("to", id).then((res) => {
          orgStructureTo = res;
        });
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.staffDetail.orgStructure.reportTo =
          this.staffDetail.orgStructure.reportTo.concat(orgStructureTo);
        this.$refs["refStaffInfo"].showReportToList = false;
        if (
          this.staffDetail.orgStructure.reportTo.length >= this.reportToTotal
        ) {
          this.$refs["refStaffInfo"].showReportToList = true;
        }
        this.bus.$emit("loading", false);
      } else {
        this.reportFromIndex++;
        let orgStructureFrom;
        await this.getOrgStructure("from", id).then((res) => {
          orgStructureFrom = res;
        });
        // console.log("====orgStructureFrom =====",orgStructureFrom)
        this.$refs["refStaffInfo"].showReportFromList = false;
        this.staffDetail.orgStructure.reportFrom =
          this.staffDetail.orgStructure.reportFrom.concat(orgStructureFrom);
        this.$refs["refStaffInfo"].showReportFromList = true;
        // console.log("====reportFrom =====",this.staffDetail.orgStructure.reportFrom)
        // console.log("==== reportFromTotal=====",this.reportFromTotal)
        if (
          this.staffDetail.orgStructure.reportFrom.length >=
          this.reportFromTotal
        ) {
          this.$refs["refStaffInfo"].showMoreFrom = false;
        }
        this.bus.$emit("loading", false);
        // console.log("====this.staffDetail =====",this.staffDetail)
      }
    },
    // 右侧点击report to 或 reporting to的list
    changeShowList(data, showCloseBtn) {
      // console.log(data,'66666');
      data._id = data.id;
      this.showStaffDetail(data, showCloseBtn, false); //第二个参数是用来控制右上角的关闭按钮显示与否
    },
    // 左侧搜索
    getData() {
      // this.bus.$emit("loading", true);
      const _this = this;
      // _this.showRight = false;
      if (this.checkIn == false) {
        this.campusSelect = {
          name: "",
          id: "",
        };
      }
      let url = "";
      url = "/m/directory/global?";

      if (_this.isFavorites == false) {
        _this.keywordType = "";
        if (_this.keyword.length < 2) {
          _this.showStaffList = false;
          _this.showNotData = false;
          console.log(_this.showNotData, "000");
          _this.bus.$emit("loading", false);
          return;
        }

        _this.searchBtn.map((i) => {
          if (i.active == true) {
            if (i.name == "Name or Email") {
              _this.keywordType = _this.keywordType + "name,email,";
            } else if (i.name == "Department") {
              _this.keywordType = _this.keywordType + "department,";
            } else if (i.name == "Specialties") {
              _this.keywordType = _this.keywordType + "employee attributes,";
            }
          }
        });

        // if(_this.keyword){
        url = url + "&keyword=" + _this.keyword;
        // }

        if (_this.keywordType) {
          url = url + "&keywordType=" + _this.keywordType;
        }
      } else {
        if (_this.tab == 1) {
          url = url + "&isFavorites=1";
        }
      }
      if (_this.campusSelect.id != "") {
        if (_this.campusSelect.id == "0") {
          url = url + "&checkedIn=all";
        } else {
          url = url + "&checkedIn=" + _this.campusSelect.id;
        }
      } else {
        this.favoritesFiterNotData = false;
      }

      if (
        localStorage.meetingIdMap != "" &&
        localStorage.meetingIdMap != undefined
      ) {
        url = url + "&meetingId=" + localStorage.meetingIdMap;
      }

      url = url + "&page=" + _this.page;
      url = url + "&pageSize=" + _this.pageSize;

      console.log(url);

      axios({
        method: "GET",
        url: url,
      })
        .then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            _this.attendees = res.data.data;
            _this.page = res.data.page + 1;
            _this.currentPage = res.data.page + 1;
            _this.pageCount = Math.ceil(res.data.total / res.data.pageSize);
            _this.pageSize = res.data.pageSize;
            _this.showStaffList = true;
            _this.showNotData = false;
            _this.attendees.map((i) => {
              i.globalShowCheckIn = localStorage.globalShowCheckIn;
            });
            //  console.log('sususususu',localStorage.globalShowCheckIn);
            if (localStorage.globalShowCheckIn != "true") {
              _this.showCheckIn = false;
              _this.showCampusSelect = false;
            } else {
              _this.showCheckIn = true;
            }
          } else {
            _this.showStaffList = false;

            if (_this.tab != 0) {
              _this.allNotData = false;
              _this.favoritesNotData = true;
              if (localStorage.globalShowCheckIn != "true") {
                _this.showCheckIn = false;
                _this.showCampusSelect = false;
              } else {
                if (_this.checkIn == true) {
                  _this.showCheckIn = true;
                } else {
                  _this.showCheckIn = false;
                }
              }

              console.log(
                _this.campusSelect.id,
                "ppppppp",
                _this.favoritesNotFiterNotData
              );
              if (_this.campusSelect.id != "") {
                _this.favoritesNotFiterNotData = false;
                _this.favoritesFiterNotData = true;
                if (localStorage.globalShowCheckIn != "true") {
                  _this.showCheckIn = false;
                  _this.showCampusSelect = false;
                } else {
                  if (
                    localStorage.meetingIdMap != "" &&
                    localStorage.meetingIdMap != undefined
                  ) {
                    _this.showCampusSelect = false;
                  } else {
                    _this.showCampusSelect = true;
                  }
                }
              } else {
                _this.favoritesFirstNotData = false;
                _this.favoritesNotFiterNotData = true;
              }
              // if(_this.favoritesFirstNotData == false){

              // }else{

              // }
            } else {
              _this.allNotData = true;
              _this.favoritesNotData = false;

              _this.showNotData = true;
              if (localStorage.globalShowCheckIn != "true") {
                _this.showCheckIn = false;
                _this.showCampusSelect = false;
              } else {
                _this.showCheckIn = true;
              }
            }
          }
          
          // _this.bus.$emit("loading", false);
        })
        .catch((err) => {
          console.log(err);
          // _this.bus.$emit("loading", false);
        });
      localStorage.skill = '';
    },

    search() {
      this.page = 0;
      this.getData();
    },

    pageChange(page) {
      this.page = page - 1;
      this.getData();
    },

    pageNext() {
      this.page = page - 1;
      this.getData();
    },

    pagePrevious() {
      this.page = page - 1;
      this.getData();
    },

    // 单独获取OrgStructure数据接口
    async getOrgStructure(type, attendeeId) {
      let data;
      // let url = '/m/attendess/'+attendeeId+'/orgStructure';
      await this.$axios({
        method: "GET",
        url: "/m/attendees/orgStructure",
        data: {
          type: type,
          page: type == "to" ? this.reportToIndex : this.reportFromIndex,
          size: this.orgStructureSize,
          attendeeId: attendeeId,
        },
      })
        .then((res) => {
          data = res.data.list;
          if (type == "to") {
            this.reportToTotal = res.data.total;
          } else {
            this.reportFromTotal = res.data.total;
          }
        })
        .catch((err) => {});
      return data;
    },

    // 显示右侧员工详细信息
    showStaffDetail(data, showCloseBtn = false, changeFirstIn = true) {
      console.log(localStorage.userId, "sunis ", data);
      if (data._id == localStorage.userId) {
        this.$router
          .push({
            path: "/setting",
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      this.showRight = false;
      console.log("data;", data);
      // this.reportToIndex = 0;
      // this.reportFromIndex = 0;
      // this.reportToTotal = 0;
      // this.reportFromTotal = 0;
      let that = this;
      this.bus.$emit("loading", true);
      this.$axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : "",
          attendeeId: data._id,
        },
      })
        .then(async (res) => {
          // data.locationShareStatus = res.data.locationShareStatus;
          this.staffDetail = res.data;
          if(data.department){
            this.staffDetail.department = data.department;
          }
          this.reportToIndex = 0;
          this.reportFromIndex = 0;
          // console.log(this.staffDetail,'009o9');
          // if(this.staffDetail.hasOwnProperty('orgStructure')){
          //   this.bus.$emit('loading', false);
          // }else{
          let orgStructureTo;
          await this.getOrgStructure("to", data._id).then((res) => {
            orgStructureTo = res;
          });
          let orgStructureFrom;
          await this.getOrgStructure("from", data._id).then((res) => {
            orgStructureFrom = res;
          });
          let orgStructure = [];
          orgStructure.reportFrom = orgStructureFrom;
          orgStructure.reportTo = orgStructureTo;
          this.staffDetail.orgStructure = orgStructure;
          console.log("siiii", this.staffDetail);
          this.bus.$emit("loading", false);
          // }
          this.showRight = true;
          this.$nextTick(() => {
            console.log("====$refs =====", that.$refs.refStaffInfo);
            this.$refs.refStaffInfo.showCloseBtn = showCloseBtn;
            if (changeFirstIn) {
              this.$refs.refStaffInfo.firstInData = res.data;
            }
          });
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });

      // this.getAttendee(data.id).then(res => {
      //   // console.log('res:', res);
      //   this.staffDetail = res.data;
      //   this.showRight = true;
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    attritutesSearch(data) {
      let _this = this;
      this.searchBtn.map((i) => {
        if (i.name == "Specialties") {
          i.active = true;
          if (i.name == "Name or Email") {
            _this.searchText = "Type name or email";
          } else if (i.name == "Department") {
            _this.searchText = "Type department";
          } else if (i.name == "Specialties") {
            _this.searchText = "Type specialties";
          }
        }else{
          i.active = false;
        }
      });
      this.tab = 0;
      this.directorySearch = true;
      this.isFavorites = false;
      if (localStorage.globalShowCheckIn != "true") {
        this.showCheckIn = false;
        this.showCampusSelect = false;
      } else {
        this.showCheckIn = true;
      }
      this.favoritesNotData = false;
      this.allNotData = true;
      this.checkIn = false;
      if (localStorage.globalShowCheckIn != "true") {
        this.showCheckIn = false;
        this.showCampusSelect = false;
      } else {
        this.showCampusSelect = false;
      }

      this.campusSelect = {
        name: "",
        id: "",
      };
      this.showStaffList = false;
      this.showNotData = false;
      this.keyword = data;
      this.page = 0;
      this.getData();
    },
    // showStaffDetail(data) {
    //   // console.log('data;',data);
    //   this.getAttendee(data.id).then(res => {
    //     // console.log('res:', res);
    //     this.staffDetail = res.data;
    //     this.showRight = true;
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    updateData(data) {
      this.attendees.map((i) => {
        if (i._id == data.id) {
          if (this.tab == 0) {
            i.isFavorite = data.isFavorite;
          } else {
            this.search();
          }
        }
      });
    },
    // 获取员工详细信息
    getAttendee(id) {
      return axios({
        method: "GET",
        url: "/m/attendees/",
        data: {
          meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : "",
          attendeeId: id,
          // token: window.localStorage.getItem('token')
        },
      });
    },
    // 中断axios请求(解决异步请求的问题)
    cancelGetMsg() {
      for (let key in this.cancel) {
        let fun = this.cancel[key];
        fun();
      }
    },
    searchBtnClick(k) {
      let _this = this;
      this.searchBtn.map((i, v) => {
        if (v != k) {
          i.active = false;
        } else {
          i.active = true;
          if (i.name == "Name or Email") {
            _this.searchText = "Type name or email";
          } else if (i.name == "Department") {
            _this.searchText = "Type department";
          } else if (i.name == "Specialties") {
            _this.searchText = "Type specialties";
          }
        }
      });
      this.checkIn = false;
      this.campusSelect = {
        name: "",
        id: "",
      };
      // this.searchBtn[k].active = !this.searchBtn[k].active;
      this.search();
    },
    getCampusData() {
      this.bus.$emit("loading", true);
      const _this = this;
      let url = "";
      url = "/m/directory/global/campus";
      axios({
        method: "GET",
        url: url,
      })
        .then((res) => {
          // console.log(res);
          _this.campusData = [
            {
              name: "All Campuses",
              id: "0",
            },
          ];
          res.data.data.map((i) => {
            _this.campusData.push(i);
          });

          _this.bus.$emit("loading", false);
        })
        .catch((err) => {
          console.log(err);
          _this.bus.$emit("loading", false);
        });
    },
    checkInChange() {
      const _this = this;
      new Promise(function (resolve, reject) {
        _this.bus.$emit("loading", true);

        let url = "";
        url = "/m/directory/global/campus";
        axios({
          method: "GET",
          url: url,
        })
          .then((res) => {
            // console.log(res);
            _this.campusData = [
              {
                name: "All Campuses",
                id: "0",
              },
            ];
            res.data.data.map((i) => {
              _this.campusData.push(i);
            });
            resolve();
            _this.bus.$emit("loading", false);
          })
          .catch((err) => {
            console.log(err);
            reject();
            _this.bus.$emit("loading", false);
          });
      }).then(
        function (ret) {
          // console.log(ret,'pppppp666');
          // console.log(_this.isFavorites);
          if (!localStorage.meetingIdMap) {
            _this.showCampusSelect = _this.checkIn;
            _this.campusSelect = {
              name: "All Campuses",
              id: "0",
            };
          } else {
            _this.campusData.map((i) => {
              if (i.id == localStorage.meetingIdMap) {
                _this.campusSelect = i;
              }
            });
            // console.log(this.campusSelect);
          }

          _this.search();
        },
        function (ret) {
          console.log(ret);
        }
      );
    },
    tabChange() {
      // console.log(this.tab);

      // this.page = 0;
      // console.log(this.tab,9999);
      if (this.tab == 0) {
        this.directorySearch = true;
        this.isFavorites = false;
        if (localStorage.globalShowCheckIn != "true") {
          this.showCheckIn = false;
          this.showCampusSelect = false;
        } else {
          this.showCheckIn = true;
        }
        this.favoritesNotData = false;
        this.allNotData = true;
      } else {
        this.directorySearch = false;
        this.isFavorites = true;

        this.allNotData = false;
        this.favoritesNotData = true;
      }
      this.checkIn = false;
      if (localStorage.globalShowCheckIn != "true") {
        this.showCheckIn = false;
        this.showCampusSelect = false;
      } else {
        this.showCampusSelect = false;
      }

      this.campusSelect = {
        name: "",
        id: "",
      };
      this.showStaffList = false;
      this.showNotData = false;
      console.log(this.showNotData, "888");
      this.search();
      // this.getData();
    },
    campusChange(item) {
      console.log(item);
      this.campusSelect = item;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.directory {
  display: flex;
  flex-direction: column;
  height: 100%;
  .v-text-field {
    margin: 0 14px;
  }
  .leftContent {
    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 60px;
      line-height: 60px;
      text-align: center;
      padding: 0 20px;
    }

    .content-container {
      border: 1px solid #999999;

      .totalNumber {
        text-align: left;
        font-size: 18px;
        margin: 5px 10px;
      }

      .content {
        height: 650px;
        background: #fff;
        text-align: center;
        overflow-y: auto;
        overflow-x: hidden;

        .notes {
          text-align: left;

          .note:before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            //background: $btn-color;
            //background: #ee2c54;
            border-radius: 50%;
            margin-right: 1rem;
          }
        }
      }

      // .staffList {
      //   .staff:nth-child(odd) {
      //     background: #f3f3f3;
      //   }

      //   .staff:nth-child(even) {
      //     background: #fff;
      //   }
      // }
    }
  }

  .rightContent {
    // height: 650px;
    // background: #fff;

    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 120px;
      text-align: center;
      padding: 0 20px;
      display: flex;

      .staff {
        display: flex;
        align-items: center;

        .right {
          text-align: left;
          font-size: 1rem;
          padding-left: 1rem;

          .name {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }

    .rightContent-content {
      height: 592px;
      overflow-y: auto;
      background: #fff;
      border: 1px solid #999;
      border-left: none;

      .staffContent {
        .staffDetails {
          padding-top: 20px;
          padding-left: 30px;

          .oneDetail {
            margin-bottom: 1rem;

            .oneDetail-title {
              display: inline-block;
              width: 15%;
              min-width: 130px;
            }
          }
        }

        .staffContent-btn {
          text-align: center;
          margin: 1.5rem auto;
        }
      }

      //上司
      .leadership {
        .x-title {
          background: #f3f3f3;
          height: 60px;
          line-height: 60px;
          color: #000;
          font-size: 1rem;
          font-weight: bold;
          padding-left: 30px;
        }

        .x-content {
          padding-left: 30px;
        }
      }

      //下属
      .subordinate {
        .x-title {
          background: #f3f3f3;
          height: 60px;
          line-height: 60px;
          color: #000;
          font-size: 1rem;
          font-weight: bold;
          padding-left: 30px;
        }

        .x-content {
          padding-left: 30px;
        }
      }

      //摘要
      .brief {
        background: #fff;
        padding-top: 2rem;
      }
    }
  }
}
.v-btn,
.v-tab {
  text-transform: none;
}
.dot-p {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .note::before {
    display: unset !important;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}
.v-select {
  padding: 0;
}
.dir-btn {
  color: #707070;
  border: 1px solid #707070;
}
// .v-tab{
//    border-bottom:1px solid #707070;
// }
// .v-tabs-bar .v-tab:not(:first-child){
//   margin-left: 0;
// }
</style>
<style lang="scss">
.icon-wujiaoxing{
  color:#fff;margin-right: 5px;
}

.selectDir {
  display: flex;
  flex-direction: column;
  border-bottom: 0.01rem solid #666;
  height: 30px;
  margin: 0 12px;
  &:focus-within{
    border-bottom: 0.01rem solid #666;
  }
  >div {
    height: 30px;
    flex-shrink: 0;
    padding: 0 .4rem 0 .1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    outline: none;
    font-size: .14rem;
    border-radius: .03rem;
    min-width: .8rem;
    &::after {
      content: '';
      border: 5px solid #666;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      right: 5px;
      top: 45%;
    }
    &:focus{
      // border-bottom-color: #409eff;
      &::after{
        content: '';
        border: 5px solid #666;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        right: 5px;
        top: 35%;
      }
      & + ul {
        display: block;
        padding-left: 0px;
        margin-top: 20px;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

    }
  }

  >ul {
    display: none;
    margin-top:.1rem;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 1px 1px 5px #000;
    // border: 0.01rem solid #666;
    width: 100%;
    cursor: pointer;
    border-radius: .03rem;
    z-index: 9;
    // &::after{
    //   filter: blur(2px); 
    // }
    &:hover{
      // border: 0.01rem solid #409eff;
    }
    
    >li{
      height: 30px;
      font-size: .14rem;
      padding: 12px;
      display: flex;
      align-items: center;
      &:hover{
        // background-color: #409eff;
        // color: #fff;
      }
    }
  }
}
.selectDir {
  div{
    font-size:12px !important;
  }
  div:empty:before {
    content: attr(placeholder); /* element attribute*/
    /*content: 'this is content';*/
    color: #dedede;
    font-size:12px !important;
  }
}
/*焦点时内容为空*/
.selectDir div:focus:before {
    content: none;
}
</style>
<!-- <style lang="scss">
.pic1{
  background-image: url('https://user-images.githubusercontent.com/8554143/34239266-232bdfc0-e641-11e7-8792-408782aaa78e.png'),linear-gradient(var(--main-color), var(--main-color));
  background-blend-mode: lighten;
  width:200px;
  height:200px;
  background-size: contain;
}
</style> -->